import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Acquisitions = () => (
  <Layout>
    <SEO title="Acquisitions" />

    <div className="page-content">
      <div className="container">
        <h1 className="display-4 text-orange">Acquisitions</h1>
        <h2>Services include:</h2>
        <div className="row my-4">
          <div className="col-md-6 col-lg-5">
            <ul>
              <li>Construction Monitoring and Damage Settlement</li>
              <li>Due Diligence</li>
              <li>Eminent Domain Support</li>
              <li>File Review/Data Entry</li>
              <li>GIS/Mapping - Project Management Database Tool</li>
              <li>Land Administration Support/Document Recording</li>
              <li>Lease Records/Asset Management</li>
              <li>Non-Environmental Permitting</li>
              <li>Oil and Gas Lease Acquisition</li>
            </ul>
          </div>
          <div className="col-md-6 col-lg-5">
            <ul>
              <li>Operations Support and Maintenance</li>
              <li>Project Management</li>
              <li>Relocation Assistance</li>
              <li>Right-of-Way Acquisition</li>
              <li>Route and Site Selection</li>
              <li>
                Surface Site Acquisition and Settlement of Surface Damages
              </li>
              <li>Survey Permissions</li>
              <li>Survey Support</li>
            </ul>
          </div>
        </div>

        <p>
          Gray Hawk Land Solutions acts as the professional representative
          between our client and the landowner. Our agents facilitate good-faith
          negotiations by using an integrated approach to achieve the desired
          result. Our agents are professionally-trained for this type of
          negotiation and deliver the results our clients have hired us to
          obtain time after time. Our agents know that time is of the essence
          and therefore respond to verbal or written inquiries from all property
          owners, lessees, licenseses, occupants, or other holders of a
          compensable interest. Our dedicated document specialists create
          complete buyers packages for our agents to ensure all local laws and
          regulations are followed.{" "}
        </p>
        <p>
          Our end goal is not only to acquire the asset on behalf of our cleint
          but to foster a mutually beneficial relationship between our client
          and the propery owner for years to come. Recognizing the importance of
          creating a solid relationship between the parties guides our agents
          actions and interactions with all landowners. This ensures a solid
          foundation for a positive relationship moving forward. This positive
          foundation allows for community involvement, respect and admiration
          for our clients in the local areas they are involved in.{" "}
        </p>
      </div>
    </div>
  </Layout>
)

export default Acquisitions
